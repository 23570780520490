<template>
  <v-card flat>
    <v-card-text>
      <template v-if="!local.caregiverId">
        <v-row
          justify="center"
          align="center"
        >
          <span class="reg-text">Caregiver profile not found</span>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="4">
            <base-app-number-field
              v-model="local.caregiverId"
              label="Caregiver ID"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.email"
              label="Email"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-number-field
              v-model="local.version"
              label="Version"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.firstName"
              label="First name"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.middleName"
              label="Middle name"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.lastName"
              label="Last name"
              disabled
            />
          </v-col>

          <v-col cols="4">
            <base-app-text-field
              :value="formatDate(local.dob, 'YYYY-MM-DD')"
              label="Date of Birth"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.ssn"
              label="SSN"
              disabled
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
  import moment from 'moment'

  export default {
    name: 'CaregiverProfileTab',
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      local: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('update:value', value)
        },
      },
    },
    methods: {
      formatDate (value, format) {
        if (value) {
          return moment(value).format(format)
        }
        return ''
      },
    },
  }
</script>
<style scoped>
.reg-text {
  color: grey
}
</style>
