<template>
  <v-card flat>
    <v-card-text>
      <template v-if="!local.id">
        <v-row>
          <v-col cols="12">
            ei agency profilea
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            Agency
          </v-col>
          <v-col cols="2">
            <base-app-number-field
              v-model="local.agencyId"
              label="Agency ID"
              disabled
            />
          </v-col>
          <v-col cols="2">
            <base-app-text-field
              v-model="local.agencyExternalId"
              label="Agency External ID"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.agencyName"
              label="Agency name"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.agencyUuid"
              label="Agency UUID"
              disabled
            />
          </v-col>
          <v-col cols="12">
            Profile
          </v-col>
          <v-col cols="4">
            <base-app-number-field
              v-model="local.id"
              label="Agency profile ID"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="local.profileStatus"
              item-text="title"
              item-value="status"
              :items="statuses"
              label="Profile status"
              outlined
              background-color="ccgrey lighten-3"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="local.staffStatus"
              item-text="title"
              item-value="staffStatus"
              :items="staffStatuses"
              label="Staff status"
              outlined
              background-color="ccgrey lighten-3"
              disabled
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.firstName"
              label="First name"
              :rules="nameRule"
              required
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.middleName"
              label="Middle name"
              :rules="nameRule"
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.lastName"
              label="Last name"
              :rules="lastNameRule"
              required
            />
          </v-col>
          <v-col cols="4">
            <base-app-text-field
              v-model="local.email"
              label="Email"
              :rules="emailRule"
              required
            />
          </v-col>
          <v-col cols="2">
            <v-menu
              v-model="dobMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <base-app-text-field
                  v-model="formattedDob"
                  label="Date of Birth"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  :rules="dobRule"
                  v-bind="attrs"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="formattedDob"
                color="primaryTeal"
                header-color="primaryTeal"
                @input="dobMenu = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="2">
            <base-app-text-field
              v-model="local.ssn"
              label="SSN"
              :rules="requiredRule"
              required
            />
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="local.gender"
              item-text="title"
              item-value="gender"
              :items="genderTypes"
              label="Gender"
              outlined
              attach
            />
          </v-col>
          <v-col cols="2">
            <base-app-text-field
              v-model="formattedHireDate"
              label="Hire date"
              persistent-hint
              prepend-icon="mdi-calendar"
              background-color="ccgrey lighten-3"
              disabled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            Address
          </v-col>
          <template v-if="local.address">
            <v-col cols="4">
              <base-app-text-field
                v-model="local.address.street"
                label="Street"
                :rules="requiredRule"
                required
              />
            </v-col>
            <v-col cols="4">
              <base-app-text-field
                v-model="local.address.city"
                label="City"
                :rules="requiredRule"
                required
              />
            </v-col>
            <v-col cols="4">
              <base-app-text-field
                v-model="local.address.state"
                label="State"
                :rules="requiredRule"
                required
              />
            </v-col>
            <v-col cols="4">
              <base-app-text-field
                v-model="local.address.zip"
                label="Zip"
                :rules="requiredRule"
                required
              />
            </v-col>
            <v-col cols="4">
              <base-app-text-field
                v-model="local.address.apartment"
                label="Apartment"
              />
            </v-col>
            <v-col cols="4">
              <base-app-text-field
                v-model="local.address.timezone"
                label="Timezone"
                disabled
              />
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col cols="12">
            Phones
          </v-col>
          <v-col cols="3">
            <base-icon-button
              @click="local.phones.push({
                number: null,
                type: 1,
                primary: false
              })"
            >
              mdi-plus-circle
            </base-icon-button>
          </v-col>
        </v-row>
        <v-row>
          <template v-for="(item, indx) in local.phones">
            <v-col
              :key="`number-${indx}`"
              cols="4"
            >
              <base-app-text-field
                v-model="item.number"
                label="Phone number"
              />
            </v-col>
            <v-col
              :key="`type-${indx}`"
              cols="4"
            >
              <v-select
                v-model.number="item.type"
                item-text="title"
                item-value="type"
                :items="phoneTypes"
                label="Type"
                outlined
                attach
              />
            </v-col>
            <v-col
              :key="`primary-${indx}`"
              cols="2"
            >
              <v-checkbox
                v-model="item.primary"
                label="Primary"
                return-object
              />
            </v-col>
            <v-col
              :key="`btn-${indx}`"
              cols="2"
            >
              <base-icon-button
                color="ccred"
                @click="local.phones.splice(indx,1)"
              >
                mdi-minus-circle
              </base-icon-button>
            </v-col>
          </template>
        </v-row>
        <template v-if="local.emergencyContact">
          <v-row>
            <v-col cols="12">
              Emergency Contact
            </v-col>
            <v-col cols="3">
              <base-app-text-field
                v-model="local.emergencyContact.name"
                label="Name"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="local.emergencyContact.relation"
                item-text="title"
                item-value="relation"
                :items="relationTypes"
                label="Relation"
                outlined
                attach
              />
            </v-col>
            <v-col cols="3">
              <base-app-text-field
                v-model="local.emergencyContact.primaryContactNumber"
                label="Primary Contact Number"
              />
            </v-col>
            <v-col cols="3">
              <base-app-text-field
                v-model="local.emergencyContact.secondaryContactNumber"
                label="Secondary Contact Number"
              />
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12">
            Case Preferences
          </v-col>
          <template
            v-if="local.casePreferences"
          >
            <v-col cols="3">
              <base-app-text-field
                v-model="local.casePreferences.preferredHoursEnd"
                label="preferredHoursEnd"
              />
            </v-col>
            <v-col cols="3">
              <base-app-text-field
                v-model="local.casePreferences.preferredHoursStart"
                label="preferredHoursStart"
              />
            </v-col>
            <v-col cols="3">
              <base-app-number-field
                v-model="local.casePreferences.maxTravelTime"
                label="maxTravelTime"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="local.casePreferences.transportType"
                item-text="title"
                item-value="type"
                :items="transportTypes"
                label="transportType"
                outlined
                attach
              />
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col cols="12">
            Preferences
          </v-col>
          <template v-for="(item, indx2) in local.preferences">
            <v-col
              :key="`name-${indx2}`"
              cols="4"
            >
              <base-app-text-field
                v-model="item.name"
                label="name"
                disabled
              />
            </v-col>
            <v-col
              :key="`prefId-${indx2}`"
              cols="4"
            >
              <base-app-number-field
                v-model="item.preferenceId"
                label="preferenceId"
                disabled
              />
            </v-col>
            <v-col
              :key="`val-${indx2}`"
              cols="4"
            >
              <base-app-text-field
                v-model="item.value"
                label="value"
                disabled
              />
            </v-col>
          </template>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
  import moment from 'moment'
  import { GenderTypes, PhoneTypes, RelationTypes, StaffStatusTypes, StatusTypes, TransportTypes } from '@/enums'

  export default {
    name: 'AgencyProfileTab',
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        // enums
        phoneTypes: PhoneTypes,
        genderTypes: GenderTypes,
        relationTypes: RelationTypes,
        statuses: StatusTypes,
        staffStatuses: StaffStatusTypes,
        transportTypes: TransportTypes,
        // DOB menu state
        dobMenu: false,
        requiredRule: [
          v => !!v || false,
        ],
        dobRule: [
          v => !!v || false,
          v => this.isValidDob(v) || 'Caregiver age must be between 18 and 100 years',
        ],
        hireDateRule: [
          v => !!v || false,
          v => this.isValidHireDate(v) || 'Hire date must be in past',
        ],
        nameRule: [
          v => !!v || false,
          v => this.isValidLength(v, 25) || 'Max 25 characters',
        ],
        lastNameRule: [
          v => !!v || false,
          v => this.isValidLength(v, 50) || 'Max 50 characters',
        ],
        emailRule: [
          v => !!v || false,
          v => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(v) || 'Invalid e-mail.'
          },
        ],
      }
    },
    computed: {
      local: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('update:value', value)
        },
      },
      formattedUserDob: {
        get () {
          return this.formatDate(this.local.userDob, 'YYYY-MM-DD')
        },
        set (val) {
          this.userDob = val
        },
      },
      formattedDob: {
        get () {
          return this.formatDate(this.local.dob, 'YYYY-MM-DD')
        },
        set (val) {
          this.local.dob = val
        },
      },
      formattedHireDate: {
        get () {
          return this.formatDate(this.local.hireDate, 'YYYY-MM-DD')
        },
        set (val) {
          this.local.hireDate = val
        },
      },
    },
    watch: {
      local: () => {
      },
    },
    methods: {
      formatDate (value, format) {
        if (value) {
          return moment(value).format(format)
        }
        return ''
      },
      isValidDob (value) {
        const age = this.calculateAge(value)
        if (value && (age >= 18 && age <= 100)) {
          return true
        }
        return false
      },
      isValidHireDate (value) {
        if (value) {
          return moment().isAfter(value)
        }
        return false
      },
      isValidLength (value, maxLength) {
        if (value) {
          return value.length <= maxLength
        }
        return false
      },
      calculateAge (value) {
        if (!value) {
          return
        }
        return moment().diff(moment(value, 'YYYY-MM-DD'), 'years')
      },
    },
  }
</script>
<style scoped>

</style>
