<template>
  <v-dialog
    v-model="aProfile"
    persistent
    scrollable
    width="1200"
  >
    <v-card>
      <base-app-dialog-title
        title="EDIT CAREGIVER"
        @close="onCancel"
      >
        <v-tabs
          v-model="tab"
        >
          <v-tab>
            User profile
          </v-tab>
          <v-tab>
            Caregiver profile
          </v-tab>
          <v-tab>
            Agency profile
          </v-tab>
        </v-tabs>
      </base-app-dialog-title>
      <v-card-text class="pt-0">
        <v-form
          ref="ffa"
          v-model="valid"
        >
          <v-tabs-items v-model="tab">
            <v-tab-item
              key="1"
            >
              <user-profile-tab v-model="userProfile" />
            </v-tab-item>
            <v-tab-item
              key="2"
            >
              <caregiver-profile-tab v-model="caregiverProfile" />
            </v-tab-item>
            <v-tab-item
              key="3"
            >
              <agency-profile-tab v-model="caregiverAgencyProfile" />
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <base-app-dialog-action-button
          @click="updateCaregiver"
        >
          Update
        </base-app-dialog-action-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { eventBus, busEvents } from '@/EventBus'
  import UserProfileTab from './UserProfileTab.vue'
  import CaregiverProfileTab from './CaregiverProfileTab.vue'
  import AgencyProfileTab from './AgencyProfileTab.vue'

  export default {
    name: 'CGEditDialog',
    components: {
      UserProfileTab,
      CaregiverProfileTab,
      AgencyProfileTab,
    },
    props: {
      aProfile: {
        type: Object,
        default: () => {},
      },
      cProfile: {
        type: Object,
        default: () => {},
      },
      uProfile: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        // Local copy of props
        caregiverAgencyProfile: this.aProfile ? JSON.parse(JSON.stringify(this.aProfile)) : {},
        userProfile: this.uProfile ? JSON.parse(JSON.stringify(this.uProfile)) : {},
        caregiverProfile: this.cProfile ? JSON.parse(JSON.stringify(this.cProfile)) : {},
        // tab indicator
        tab: 0,
        // is form valid
        valid: false,
      }
    },
    methods: {
      onCancel () {
        this.$emit('close')
      },
      async updateCaregiver () {
        const data = {
          agencyProfile: {
            ...this.aProfile,
            ...this.caregiverAgencyProfile,
          },
        }

        const { id, agencyId } = this.aProfile
        this.$careConnect.updateCaregiverAgencyProfile(id, agencyId, data)
          .then((resp) => {
            this.$emit('close', resp.caregiver)
            const text = 'Caregiver profile updated'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'success', text })
          })
          .catch((err) => {
            const text = err.json ? err.json.error : 'Failed to update agency profile'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
      },
    },
  }
</script>
